import {Appbar, withTheme} from "react-native-paper";
import React, {Component, useEffect} from "react";
import {
    Box, CardActionArea, CardMedia,
    createTheme, Dialog, Divider, Fab, FormControl, Grid, InputLabel, OutlinedInput, Stack, TextField, ThemeProvider,
} from "@mui/material";
import CryptoJS from "react-native-crypto-js";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import {
    Link,
    Route,
    Routes,
    MemoryRouter, BrowserRouter,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import List from "@mui/material/List";
import Info from "./pages/info";
import {Text} from "react-native-web";
import IconButton from "@mui/material/IconButton";
import * as Icons from "@mui/icons-material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Card from "@mui/material/Card";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import Index from "./pages";
import { decode } from "hex-encode-decode";
import Container from "@mui/material/Container";
import CircularProgress from "@mui/material/CircularProgress";
import BottomSheet from "./assets/bottom_sheet_mui/src/components/BottomSheet/BottomSheet";
import Backdrop from "@mui/material/Backdrop";
import Account from "./pages/account";
import Watched from "./pages/watched";
import Favorite from "./pages/favorite";
import News from "./pages/news";
import Genre from "./pages/genre";
import NewAnime from "./pages/newanime";
import Animelist from "./pages/animelist";
import Help from "./pages/help";
import Community from "./pages/community";
import Update from "./pages/update";
import moment from "moment";
import AnimeDate from "./pages/animedate";
import Leaderboard from "./pages/leaderboard";
import Patreon from "./pages/patreon";
import AppUpdate from "./pages/app_update";
import {ArrowBack} from "@mui/icons-material";
window.Buffer = require("Buffer");
function Router(props) {
    const { children } = props;
    return (
        <MemoryRouter initialEntries={['/drafts']} initialIndex={0}>
            {children}
        </MemoryRouter>
    );
}
Router.propTypes = {
    children: PropTypes.node,
};

let dark = createTheme({
        components: {
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        // Some CSS
                        color: 'rgba(255,255,255,0.72)',
                    },
                },
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        color: 'white',
                        backgroundColor: '#252e39'
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        color: 'white',
                        backgroundColor: '#182128'
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        color: 'white',
                        backgroundColor: '#252e39'
                    }
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: "white"
                    }
                }
            },
            MuiPaginationItem: {
                styleOverrides: {
                    root: {
                        color: "white"
                    }
                }
            },
            MuiPagination: {
                styleOverrides: {
                    ul: {
                        placeContent: 'center'
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        color: "#f1f1f1"
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    root: {
                        marginTop: "0px"
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#262e39',
                        color: 'white',
                        fontFamily: "'Changa' !important"
                    }
                }
            },
            MuiListItemText: {
                styleOverrides: {
                    root: {
                        color: 'white',
                        fontFamily: "'Changa' !important"
                    }
                }
            },
            MuiListItem: {
                styleOverrides: {
                    "root": {
                        "&.Mui-selected": {
                            "background": "rgb(37 46 57)"
                        }
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        color: "white",
                        fontFamily: "'Changa' !important"
                    }
                }
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        fontFamily: "'Changa' !important"
                    }
                }
            },
        },
    });
let light = createTheme({
        components: {
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        // Some CSS
                        color: 'rgba(0,0,0,0.72)',
                    },
                },
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        color: 'white',
                        backgroundColor: '#ffffff'
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        color: 'white',
                        backgroundColor: '#efeeee'
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        color: 'white',
                        backgroundColor: '#ffffff'
                    }
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: "rgba(0,0,0,0.60)"
                    }
                }
            },
            MuiPaginationItem: {
                styleOverrides: {
                    root: {
                        color: "black"
                    }
                }
            },
            MuiPagination: {
                styleOverrides: {
                    ul: {
                        placeContent: 'center'
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        color: "#010101"
                    }
                }
            },
            MuiDialog: {
                styleOverrides: {
                    root: {
                        marginTop: "0px"
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        backgroundColor: '#ffffff',
                        color: 'black',
                        fontFamily: "'Changa' !important"
                    }
                }
            },
            MuiListItemText: {
                styleOverrides: {
                    root: {
                        color: 'black',
                        fontFamily: "'Changa' !important"
                    }
                }
            },
            MuiListItem: {
                styleOverrides: {
                    "root": {
                        "&.Mui-selected": {
                            "background": "rgb(255 255 255 / 87%)"
                        }
                    }
                }
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        color: "black",
                        fontFamily: "'Changa' !important"
                    }
                }
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        fontFamily: "'Changa' !important"
                    }
                }
            },
        },
    });
let audio = new Audio('https://app.sanime.net/secure/audio.mp3');
audio.muted = true;

window.moments = moment;
moments.locale("ar-sa");

Object.defineProperty(Array.prototype, 'array_chunk', {
    value: function(chunkSize) {
        let array = this;
        return [].concat.apply([],
            array.map(function(elem, i) {
                return i % chunkSize ? [] : [array.slice(i, i + chunkSize)];
            })
        );
    }
});

export function uu_id() {
    return 'xxxxxxxxxxxx4xxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function decodeTool(res) {
    var hex  = atob(res);
    var str = '';
    for (var n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
}
export function CenterLoading() {
    return <Box style={{height: '100%'}}>
        <Container style={{placeContent: 'center',textAlign: 'center',height: '100%'}}>
            <div style={{height: '45%'}} />
            <CircularProgress style={{color: '#00b2ff'}}/>
            <div/>
        </Container>
    </Box>
}

export function CenterEmpty() {
    return <Box style={{height: '100%'}}>
        <Container style={{placeContent: 'center',textAlign: 'center',height: '100%'}}>
            <div style={{height: '45%'}} />
            <Typography style={{marginTop: "4px"}}>
                الصفحة خالية
            </Typography>
            <div/>
        </Container>
    </Box>
}
let isAndroid = false;
let isIos = false;
class App extends Component {
    componentDidMount(){
        const passhash = CryptoJS.MD5(navigator.userAgent).toString();
        window.CryptoJS = CryptoJS;
        if (passhash === "875872353699da983cbd784affaf1a6d") {
            isAndroid = true;
            window.isAndroid = true
            isIos = false;
            document.title = "SAnime | اس أنمي | ANDROID";
        } else if (passhash === "30a0d5eb921ede79fd4690bad5a6928a") {
            isAndroid = false;
            window.isAndroid = false
            isIos = true;
            document.title = "SAnime | اس أنمي | IOS";
        } else {
            isAndroid = false;
            window.isAndroid = false
            isIos = false;
            react.deny();
        }

        if (localStorage.getItem("userAddress") == null) {
            localStorage.setItem("userAddress",uu_id() );
        }
        if (localStorage.getItem("darkMode") == null) {
            document.body.style.backgroundColor = "#182128";
            this.setState({
                isDark: true
            });
            localStorage.setItem("darkMode","true");
        } else {
            if (localStorage.getItem("darkMode").includes("true")) {
                document.body.style.backgroundColor = "#182128";
                this.setState({
                    isDark: true
                });
                localStorage.setItem("darkMode","true");
                if (window.isAniWatch != null) {
                    if (window.webkit != null) {
                        window.webkit.messageHandlers.sendMessage.postMessage({
                            themeMode: true,
                            themeColor: "#182128",
                            cardColor: "#262e39",
                            textColor: "#ffffff"
                        });
                    }
                }
            } else {
                document.body.style.backgroundColor = "#efeeee";
                this.setState({
                    isDark: false
                });
                localStorage.setItem("darkMode","false");
                if (isAndroid) {
                    window.Android.code("ICAgICAgICBpZiAoYW5kcm9pZC5vcy5CdWlsZC5WRVJTSU9OLlNES19JTlQgPj0gYW5kcm9pZC5vcy5CdWlsZC5WRVJTSU9OX0NPREVTLk0pIHsKICAgICAgICAgICAgaW50IGZsYWdzID0gbWFpbkFjdGl2aXR5LmdldFdpbmRvdygpLmdldERlY29yVmlldygpLmdldFN5c3RlbVVpVmlzaWJpbGl0eSgpOwogICAgICAgICAgICBmbGFncyB8PSBhbmRyb2lkLnZpZXcuVmlldy5TWVNURU1fVUlfRkxBR19MSUdIVF9TVEFUVVNfQkFSOwogICAgICAgICAgICBpZiAoYW5kcm9pZC5vcy5CdWlsZC5WRVJTSU9OLlNES19JTlQgPj0gYW5kcm9pZC5vcy5CdWlsZC5WRVJTSU9OX0NPREVTLk8pIHsKICAgICAgICAgICAgICAgIG1haW5BY3Rpdml0eS5nZXRXaW5kb3coKS5nZXREZWNvclZpZXcoKS5zZXRTeXN0ZW1VaVZpc2liaWxpdHkoZmxhZ3MgfCBhbmRyb2lkLnZpZXcuVmlldy5TWVNURU1fVUlfRkxBR19MSUdIVF9OQVZJR0FUSU9OX0JBUik7CiAgICAgICAgICAgICAgICBtYWluQWN0aXZpdHkuZ2V0V2luZG93KCkuc2V0TmF2aWdhdGlvbkJhckNvbG9yKGFuZHJvaWQuZ3JhcGhpY3MuQ29sb3IuV0hJVEUpOwogICAgICAgICAgICB9IGVsc2UgewogICAgICAgICAgICAgICAgbWFpbkFjdGl2aXR5LmdldFdpbmRvdygpLmdldERlY29yVmlldygpLnNldFN5c3RlbVVpVmlzaWJpbGl0eShmbGFncyk7CiAgICAgICAgICAgIH0KICAgICAgICAgICAgbWFpbkFjdGl2aXR5LmdldFdpbmRvdygpLnNldFN0YXR1c0JhckNvbG9yKGFuZHJvaWQuZ3JhcGhpY3MuQ29sb3IuV0hJVEUpOwogICAgICAgIH0=");
                }
                if (window.isAniWatch != null) {
                    if (window.webkit != null) {
                        window.webkit.messageHandlers.sendMessage.postMessage({
                            themeMode: false,
                            themeColor: "#efeeee",
                            cardColor: "#ffffff",
                            textColor: "#000000"
                        });
                    }
                }
            }
        }
    }
    constructor(props) {
        super(props);
        window.AudioPlayer = audio;
        this.state = {
            isDark: true,
            drawer: false,
            loading: true,
            isInfo: window.location.href.toString().includes("info"),
            searchDialog: false,
            searchedTitle: '',
            backdrop: false,
            searchedData: [],
            searched: false,
            homeTitle: 'الصفحة الرئيسية',
            lottieDialog: false,
            randomAnime: '/info?id=532',
            userData: {},
            hasLogin: false,
            premium: '',
            fullscreen: false,
            timer: 5,
            appUpdate: false
        };
        this.animationRef = React.createRef();
        window.react = this;
        localStorage.setItem("latestAnime","");
        this.checkAccount();
        this.relogin();
        this.saveVisit();
        setTimeout(() => {
            let onlineScript = document.createElement('script');
            onlineScript.src = 'https://app.sanime.net/online.js?time'+ new Date().getTime();
            onlineScript.id = "onlineScript";
            onlineScript.async = true;
            document.head.appendChild(onlineScript);
        },500);
    }

    closeFullscreen = () => {
        this.setState({
            fullscreen: false
        })
    }

    loadAds = () => {
        if (window.isAniWatch != null) {
            if (window.webkit != null) {
                window.webkit.messageHandlers.sendMessage.postMessage({
                    loadAds: true,
                });
            }
        }
    }

    showAds = () => {
        if (window.isAniWatch != null) {
            if (window.webkit != null) {
                window.webkit.messageHandlers.sendMessage.postMessage({
                    showAds: true,
                });
            }
        }
    }

    openFullscreen = () => {
        //this.setState({
        //    fullscreen: true,
        //    timer: 6
        //});
        //setTimeout(function () {
       //     window.react.closeFullscreen();
        //},6000);
    }

    deny = () => {
        setTimeout(() => {
            document.body.innerHTML = "غير مسموح";
            document.body.style.placeItems = "center";
            document.body.style.justifyContent = "center";
            document.body.style.color = "white";
            document.body.style.fontFamily = "'Changa'";
        },100);
        setTimeout(() => {
            window.close();
        },5000);
    }
    goAccount = () => {
        window.favoritePage.getAccount();
    }
    createAds = () => {
        let srcAds;
        if (localStorage.getItem("logged") !== null) {
            let data = JSON.parse(localStorage.getItem("loggedData"));
            if (!window.react.state.premium.includes(data.email)) {
                srcAds = document.createElement("script");
                srcAds.setAttribute("data-cfasync","false");
                srcAds.setAttribute("type","text/javascript");
                srcAds.setAttribute("src","https://predictivadvertising.com/a/display.php?r=6279822");
                //document.body.appendChild(srcAds);
            }
        } else {
            srcAds = document.createElement("script");
            srcAds.setAttribute("data-cfasync","false");
            srcAds.setAttribute("type","text/javascript");
            srcAds.setAttribute("src","https://predictivadvertising.com/a/display.php?r=6279822");
            //document.body.appendChild(srcAds);
        }
    }

    checkAds = () => {
        if (localStorage.getItem("logged") !== null) {
            let data = JSON.parse(localStorage.getItem("loggedData"));
            return !window.react.state.premium.includes(data.email);
        } else {
            return true
        }
    }

    checkAccount = () => {
        setTimeout(() => {
            if (localStorage.getItem("logged") !== null) {
                let data = JSON.parse(localStorage.getItem("loggedData"));
                this.setState({
                    userData: data,
                    hasLogin: true
                })
            }
        },200);
    }

    saveVisit = () => {
        if (localStorage.getItem("visit") !== null) {
            let count = parseInt(localStorage.getItem("visit")) + 1;
            localStorage.setItem("visit", count);
        } else {
            localStorage.setItem("visit", 1);
        }
    };

    getVisitCountSync = () => {
        try {
            const count = localStorage.getItem('visit');
            return count ? parseInt(count) : 0;
        } catch (error) {
            console.error('Error:', error);
            return 0;
        }
    };

    relogin = () => {
        setTimeout(() => {
            if (localStorage.getItem("logged") !== null) {
                let data = JSON.parse(localStorage.getItem("loggedData"));
                let params = new URLSearchParams();
                params.append('email', data.email);
                params.append('password', data.password);
                fetch('https://app.sanime.net/function/h10.php?page=login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: params
                })
                .then(response => response.json())
                .then(json => {
                    if (json.code === "1") {
                        localStorage.setItem("logged", true);
                        localStorage.setItem("loggedData", JSON.stringify(json.message));
                        window.react.checkAccount();
                    } else {
                        localStorage.removeItem("logged");
                        localStorage.removeItem("loggedData");
                        window.react.checkAccount();
                    }
                })
                .catch(error => {});
            }
        }, 200);
    }

    changeTheme = () => {
        if (this.state.isDark) {
            document.body.style.backgroundColor = "#efeeee";
            this.setState({
                isDark: false
            });
            localStorage.setItem("darkMode","false");
            if (isAndroid) {
                window.Android.code("ICAgICAgICBpZiAoYW5kcm9pZC5vcy5CdWlsZC5WRVJTSU9OLlNES19JTlQgPj0gYW5kcm9pZC5vcy5CdWlsZC5WRVJTSU9OX0NPREVTLk0pIHsKICAgICAgICAgICAgaW50IGZsYWdzID0gbWFpbkFjdGl2aXR5LmdldFdpbmRvdygpLmdldERlY29yVmlldygpLmdldFN5c3RlbVVpVmlzaWJpbGl0eSgpOwogICAgICAgICAgICBmbGFncyB8PSBhbmRyb2lkLnZpZXcuVmlldy5TWVNURU1fVUlfRkxBR19MSUdIVF9TVEFUVVNfQkFSOwogICAgICAgICAgICBpZiAoYW5kcm9pZC5vcy5CdWlsZC5WRVJTSU9OLlNES19JTlQgPj0gYW5kcm9pZC5vcy5CdWlsZC5WRVJTSU9OX0NPREVTLk8pIHsKICAgICAgICAgICAgICAgIG1haW5BY3Rpdml0eS5nZXRXaW5kb3coKS5nZXREZWNvclZpZXcoKS5zZXRTeXN0ZW1VaVZpc2liaWxpdHkoZmxhZ3MgfCBhbmRyb2lkLnZpZXcuVmlldy5TWVNURU1fVUlfRkxBR19MSUdIVF9OQVZJR0FUSU9OX0JBUik7CiAgICAgICAgICAgICAgICBtYWluQWN0aXZpdHkuZ2V0V2luZG93KCkuc2V0TmF2aWdhdGlvbkJhckNvbG9yKGFuZHJvaWQuZ3JhcGhpY3MuQ29sb3IuV0hJVEUpOwogICAgICAgICAgICB9IGVsc2UgewogICAgICAgICAgICAgICAgbWFpbkFjdGl2aXR5LmdldFdpbmRvdygpLmdldERlY29yVmlldygpLnNldFN5c3RlbVVpVmlzaWJpbGl0eShmbGFncyk7CiAgICAgICAgICAgIH0KICAgICAgICAgICAgbWFpbkFjdGl2aXR5LmdldFdpbmRvdygpLnNldFN0YXR1c0JhckNvbG9yKGFuZHJvaWQuZ3JhcGhpY3MuQ29sb3IuV0hJVEUpOwogICAgICAgIH0=");
            }
            var message = {
                themeMode: !this.state.isDark,
                themeColor: "#efeeee",
                cardColor: "#ffffff",
                textColor: "#000000"
            };
        } else {
            document.body.style.backgroundColor = "#182128";
            this.setState({
                isDark: true
            });
            localStorage.setItem("darkMode","true");
            if (isAndroid) {
                window.Android.code("ICAgICAgICBpZiAoYW5kcm9pZC5vcy5CdWlsZC5WRVJTSU9OLlNES19JTlQgPj0gYW5kcm9pZC5vcy5CdWlsZC5WRVJTSU9OX0NPREVTLk0pIHsKICAgICAgICAgICAgaW50IGZsYWdzID0gbWFpbkFjdGl2aXR5LmdldFdpbmRvdygpLmdldERlY29yVmlldygpLmdldFN5c3RlbVVpVmlzaWJpbGl0eSgpOwogICAgICAgICAgICBmbGFncyA9IGZsYWdzIF4gYW5kcm9pZC52aWV3LlZpZXcuU1lTVEVNX1VJX0ZMQUdfTElHSFRfU1RBVFVTX0JBUjsKICAgICAgICAgICAgaWYgKGFuZHJvaWQub3MuQnVpbGQuVkVSU0lPTi5TREtfSU5UID49IGFuZHJvaWQub3MuQnVpbGQuVkVSU0lPTl9DT0RFUy5PKSB7CiAgICAgICAgICAgICAgICBtYWluQWN0aXZpdHkuZ2V0V2luZG93KCkuZ2V0RGVjb3JWaWV3KCkuc2V0U3lzdGVtVWlWaXNpYmlsaXR5KGZsYWdzIF4gYW5kcm9pZC52aWV3LlZpZXcuU1lTVEVNX1VJX0ZMQUdfTElHSFRfTkFWSUdBVElPTl9CQVIpOwogICAgICAgICAgICAgICAgbWFpbkFjdGl2aXR5LmdldFdpbmRvdygpLnNldE5hdmlnYXRpb25CYXJDb2xvcihhbmRyb2lkLmdyYXBoaWNzLkNvbG9yLnBhcnNlQ29sb3IoIiMyNjJlMzkiKSk7CiAgICAgICAgICAgIH0gZWxzZSB7CiAgICAgICAgICAgICAgICBtYWluQWN0aXZpdHkuZ2V0V2luZG93KCkuZ2V0RGVjb3JWaWV3KCkuc2V0U3lzdGVtVWlWaXNpYmlsaXR5KGZsYWdzKTsKICAgICAgICAgICAgfQogICAgICAgICAgICBtYWluQWN0aXZpdHkuZ2V0V2luZG93KCkuc2V0U3RhdHVzQmFyQ29sb3IoYW5kcm9pZC5ncmFwaGljcy5Db2xvci5wYXJzZUNvbG9yKCIjMTgyMTI4IikpOwogICAgICAgIH0=");
            }
            var message = {
                themeMode: !this.state.isDark,
                themeColor: "#182128",
                cardColor: "#262e39",
                textColor: "#ffffff"
            };
        }
        if (window.isAniWatch != null) {
            if (window.webkit != null) {
                window.webkit.messageHandlers.sendMessage.postMessage(message);
            }
        }
    }
    openDrawer = () => {
        this.setState({ drawer: true });
    }
    closeDrawer = () => {
        this.setState({ drawer: false });
    }
    closeSearch = () => {
        this.setState({
            searchDialog: false
        });
    }
    closeSearched = () => {
        this.setState({
            searchedData: [],
            searched: false,
            searchDialog: false,
            searchedTitle: ''
        });
    }
    changedSearch = (value) => {
        this.setState({
            searchedTitle: value.target.value
        });
    }
    goSearch = async () => {
        this.setState({
            backdrop: true,
            searchDialog: false
        });
        const isLoggedIn = localStorage.getItem("logged") !== null;
        let url = "";
        var visit = 0;
        visit = await this.getVisitCountSync();
        if (isLoggedIn) {
            const data = JSON.parse(localStorage.getItem("loggedData"));
            url = `https://app.sanime.net/function/h10.php?page=search&email=${data.email}`+`&name=`+this.state.searchedTitle+"&visit="+visit;
        } else {
            url = `https://app.sanime.net/function/h10.php?page=search&name=`+this.state.searchedTitle+"&visit="+visit;
        }
        fetch(url).then((value) => {
            value.text().then((value) => {
                let JS = JSON.parse(value);
                this.setState({
                    searchedData: JS,
                    searched: true,
                    backdrop: false,
                });
            });
        }).catch((error) => {
            this.setState({
                backdrop: false,
            });
        });
    }
    openSearch = () => {
        //window.react.showAds()
        this.setState({
            searchDialog: true
        });
        if (this.state.isDark) {
            document.getElementById("searchItem").setAttribute("style","color: white;");
        } else {
            document.getElementById("searchItem").setAttribute("style","color: black;");
        }
    }

    showLottie = () => {
        this.setState({
            lottieDialog: true,
        });
        audio.reload
        audio.play();
        audio.loop = true;
        setTimeout(() => {
            lottie.loadAnimation({
                container: document.getElementById('svgContainer'), // the dom element that will contain the animation
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: 'https://assets9.lottiefiles.com/animated_stickers/lf_tgs_IZvR5v.json' // the path to the animation json
            });
        },100);
        setTimeout(() => {
            fetch('https://app.sanime.net/function/h10.php?page=random').then((value) => {
                value.text().then((value) => {
                    audio.load();
                    audio.pause();
                    this.closeLottie();
                    const data = JSON.parse(value);
                    this.setState({
                        randomAnime: "/info?id="+data['id']
                    });
                    setTimeout(() => {
                        document.getElementById('goRandom').click();
                    },100)
                });
            });
        },1000);
    }
    closeLottie = () => {
        this.setState({
            lottieDialog: false,
        });
        document.getElementById('svgContainer').innerHTML = "";
    }
    logout = () => {
        localStorage.removeItem("logged");
        localStorage.removeItem("loggedData");
        this.setState({
            userData: {},
            hasLogin: false
        });
    }

    alerts = () => {
        Swal.fire("demo")
    }

    sendPost = () => {
        window.community.epMenuOpen();
    }

    goReward = () => {
        Swal.fire({
            title: 'شرح قائمة الأبلودر',
            icon: 'info',
            html:
                'هذا قائمة بترتيب المستخدمين الذين يقومون برفع الحلقات'+
                '<hr style="color: transparent"><hr/>' +
                'ولمعرفة عدد الحلقات التي قام برفعها المستخدم'+
                '<hr style="color: transparent"><hr/>' +
                'وسوف يتم أرسال جائزة لأكثر شخص يقوم برفع حلقات في التطبيق'+
                '<hr style="color: transparent"><hr/>' +
                'أي شخص يقوم برفع حلقات لاتعمل سيتم أزالة نتيجتة من القائمة'+
                '<hr style="color: transparent"><hr/>' +
                '<span style="color: gold">عندما تنتهي مدة ال60 يوم يرجى من صاحب الترتيب الأول التواصل معنا على صفحتنا في الانستغرام لارسال الجائزة الخاصة به</span>',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText:
                'موافق',
            confirmButtonAriaLabel: 'موافق',
        });
    }

    render() {
        return (
            <BrowserRouter>
                <ThemeProvider theme={this.state.isDark ? dark : light}>
                    <Box id={"actionBar"} style={this.state.isInfo ? {display: "none"} : {display: "block"}}>
                        <React.Fragment key={0}>
                            <SwipeableDrawer onOpen={this.openDrawer} onClose={this.closeDrawer} open={this.state.drawer}>
                                {
                                    <Box
                                        sx={{ width: 250 }}
                                        role="presentation"
                                        style={{flex: '1'}}
                                        onClick={this.closeDrawer}
                                        onKeyDown={this.closeDrawer}
                                    >
                                        <Card>
                                            <Toolbar>
                                                <img style={{width:"30px",height: "30px"}} src={"https://sanime.net/app_icon.png"}/>
                                                <MenuItem sx={{ flexGrow: 1 }} style={{textAlign: 'center', borderRadius: "8px",}}>
                                                    {
                                                        this.state.isDark ? <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{textAlign: 'center',fontFamily: 'Righteous'}}>
                                                            {localStorage.getItem("logged") !== null ? JSON.parse(localStorage.getItem("loggedData")).username : "SAnime.net"}
                                                        </Typography> : <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{textAlign: 'center',fontFamily: 'Righteous', color: 'black'}}>
                                                            {localStorage.getItem("logged") !== null ? JSON.parse(localStorage.getItem("loggedData")).username : "SAnime.net"}
                                                        </Typography>
                                                    }
                                                </MenuItem>
                                            </Toolbar>
                                        </Card>
                                        <List>
                                            <ListItem style={{padding:"0px"}}>
                                                <Link to={'/'} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset',width: '96%'}}>
                                                    <ListItem selected={location.pathname === '/'} button style={{margin: "4px", borderRadius: "8px"}}>
                                                        <ListItemIcon>
                                                            <Icons.Home/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="الصفحة الرئيسية" />
                                                    </ListItem>
                                                </Link>
                                            </ListItem>
                                            <ListItem style={{padding:"0px"}}>
                                                <Link to={'/animelist'} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset',width: '96%'}}>
                                                    <ListItem selected={location.pathname === '/animelist'} button style={{margin: "4px", borderRadius: "8px"}}>
                                                        <ListItemIcon>
                                                            <Icons.ListTwoTone/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="قائمة الأنمي" />
                                                    </ListItem>
                                                </Link>
                                            </ListItem>
                                            <ListItem style={{padding:"0px"}}>
                                                <Link to={'/patreon'} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset',width: '96%'}}>
                                                    <ListItem selected={location.pathname === '/patreon'} button style={{margin: "4px", borderRadius: "8px"}}>
                                                        <ListItemIcon>
                                                            <Icons.AttachMoney/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="دعم التطبيق" />
                                                        {
                                                            new Date().setDate(new Date().getDate()) > 1680965907899 ? <Box/> : <Card style={{paddingRight: "7px", paddingLeft: "7px", backgroundColor: "#2196f3"}}>
                                                                <Typography style={{color: "white"}}>
                                                                    جديد
                                                                </Typography>
                                                            </Card>
                                                        }
                                                    </ListItem>
                                                </Link>
                                            </ListItem>
                                            <ListItem style={{padding:"0px"}}>
                                                <Link to={'/newanime'} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset',width: '96%'}}>
                                                    <ListItem selected={location.pathname === '/newanime'} button style={{margin: "4px", borderRadius: "8px"}}>
                                                        <ListItemIcon>
                                                            <Icons.AddTaskSharp/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="الأنميات الجديدة" />
                                                    </ListItem>
                                                </Link>
                                            </ListItem>
                                            <ListItem style={{padding:"0px"}}>
                                                <Link to={'/animedate'} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset',width: '96%'}}>
                                                    <ListItem selected={location.pathname === '/animedate'} button style={{margin: "4px", borderRadius: "8px"}}>
                                                        <ListItemIcon>
                                                            <Icons.TimerOutlined/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="مواعيد الأنمي" />
                                                    </ListItem>
                                                </Link>
                                            </ListItem>
                                            <ListItem style={{padding:"0px"}}>
                                                <Link to={'/community'} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset',width: '96%'}}>
                                                    <ListItem selected={location.pathname === '/community'} button style={{margin: "4px", borderRadius: "8px"}}>
                                                        <ListItemIcon>
                                                            <Icons.GroupsOutlined/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="المجتمع" />
                                                    </ListItem>
                                                </Link>
                                            </ListItem>
                                            <ListItem style={{padding:"0px"}}>
                                                <Link to={'/genre'} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset',width: '96%'}}>
                                                    <ListItem selected={location.pathname === '/genre'} button style={{margin: "4px", borderRadius: "8px"}}>
                                                        <ListItemIcon>
                                                            <Icons.HorizontalSplitOutlined/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="التصنيفات" />
                                                    </ListItem>
                                                </Link>
                                            </ListItem>
                                            <ListItem style={{padding:"0px"}}>
                                                <Link to={'/news'} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset',width: '96%'}}>
                                                    <ListItem selected={location.pathname === '/news'} button style={{margin: "4px", borderRadius: "8px"}}>
                                                        <ListItemIcon>
                                                            <Icons.Home/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="الأخبار" />
                                                    </ListItem>
                                                </Link>
                                            </ListItem>
                                            <ListItem style={{padding:"0px"}}>
                                                <ListItem onClick={this.showLottie} button style={{margin: "4px", borderRadius: "8px"}}>
                                                    <ListItemIcon>
                                                        <Icons.Shuffle/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="أنمي عشوائي" />
                                                </ListItem>
                                            </ListItem>
                                            <Divider/>
                                            <ListItem style={{padding:"0px"}}>
                                                <Link to={'/favorite'} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset',width: '96%'}}>
                                                    <ListItem selected={location.pathname === '/favorite'} button style={{margin: "4px", borderRadius: "8px"}}>
                                                        <ListItemIcon>
                                                            <Icons.FavoriteBorderOutlined/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="المفضلة" />
                                                    </ListItem>
                                                </Link>
                                            </ListItem>
                                            <ListItem style={{padding:"0px"}}>
                                                <Link to={'/watched'} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset',width: '96%'}}>
                                                    <ListItem selected={location.pathname === '/watched'} button style={{margin: "4px", borderRadius: "8px"}}>
                                                        <ListItemIcon>
                                                            <Icons.VisibilityOutlined/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="المشاهدات" />
                                                    </ListItem>
                                                </Link>
                                            </ListItem>
                                            <Divider/>
                                            {this.state.hasLogin ? <ListItem style={{padding:"0px"}}>
                                                <ListItem onClick={this.logout} button style={{margin: "4px", borderRadius: "8px"}}>
                                                    <ListItemIcon>
                                                        <Icons.LogoutOutlined/>
                                                    </ListItemIcon>
                                                    <ListItemText primary="تسجيل خروج" />
                                                </ListItem>
                                            </ListItem> : <ListItem style={{padding:"0px"}}>
                                                <Link to={'/account'} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset',width: '96%'}}>
                                                    <ListItem selected={location.pathname === '/account'} button style={{margin: "4px", borderRadius: "8px"}}>
                                                        <ListItemIcon>
                                                            <Icons.AccountBox/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="أعدادات الحساب" />
                                                    </ListItem>
                                                </Link>
                                            </ListItem>}
                                            {this.state.hasLogin ? <ListItem style={{padding:"0px"}}>
                                                <Link to={'/update'} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset',width: '96%'}}>
                                                    <ListItem selected={location.pathname === '/update'} button style={{margin: "4px", borderRadius: "8px"}}>
                                                        <ListItemIcon>
                                                            <Icons.ManageAccounts/>
                                                        </ListItemIcon>
                                                        <ListItemText primary="تعديل الحساب" />
                                                    </ListItem>
                                                </Link>
                                            </ListItem> : <Box/>}
                                        </List>
                                    </Box>
                                }
                            </SwipeableDrawer>
                        </React.Fragment>
                        <Appbar.Header style={this.state.isDark ? {backgroundColor: "#252e39"} : {backgroundColor: "#ffffff"}}>
                            <IconButton color="primary" aria-label="menu" onClick={this.openDrawer}>
                                <Icons.Menu />
                            </IconButton>
                            <IconButton color="primary" aria-label="search">
                                <Icons.Search style={{color: "#00000000"}} />
                            </IconButton>
                            <ContentTitle title="SAnime.net" subtitle={this.state.homeTitle} dark={this.state.isDark} />
                            <IconButton color="primary" aria-label="search" onClick={this.changeTheme}>
                                {this.state.isDark ? <Brightness7Icon /> : <Brightness4Icon />}
                            </IconButton>
                            {
                                window.location.pathname.includes("community") ? <IconButton color="primary" aria-label="make post" onClick={this.sendPost}>
                                        <Icons.PostAddOutlined />
                                    </IconButton> :
                                    window.location.pathname.includes("favorite") ? <IconButton color="primary" aria-label="sync account" onClick={this.goAccount}>
                                            <Icons.CloudDownloadOutlined />
                                        </IconButton> :
                                        window.location.pathname.includes("leaderboard") ? <IconButton color="primary" aria-label="show reward" onClick={this.goReward}>
                                                <Icons.InfoOutlined />
                                            </IconButton> :
                                <IconButton color="primary" aria-label="search" onClick={this.openSearch}>
                                    <Icons.Search />
                                </IconButton>
                            }
                        </Appbar.Header>
                    </Box>
                    <Routes>
                        {
                            this.state.appUpdate ? <Route path="/" element={<AppUpdate />} /> : <Route path="/" element={<Index />} />
                        }
                        <Route path="/animelist" element={<Animelist />} />
                        <Route path="/newanime" element={<NewAnime />} />
                        <Route path="/community" element={<Community />} />
                        <Route path="/animedate" element={<AnimeDate />} />
                        <Route path="/leaderboard" element={<Leaderboard />} />
                        <Route path="/genre" element={<Genre />} />
                        <Route path="/news" element={<News />} />
                        <Route path="/favorite" element={<Favorite />} />
                        <Route path="/watched" element={<Watched />} />
                        <Route path="/account" element={<Account />} />
                        <Route path="/update" element={<Update />} />
                        <Route path="/help" element={<Help />} />
                        <Route path="/info" element={<Info />} />
                        <Route path="/patreon" element={<Patreon/>} />
                        <Route path="/appupdate" element={<AppUpdate/>} />

                    </Routes>
                    <Dialog
                        fullScreen={true}
                        onClose={this.closeSearched}
                        open={this.state.searched}
                    >
                        <Appbar.Header style={this.state.isDark ? {backgroundColor: "#252e39"} : {backgroundColor: "#ffffff"}}>
                            <Appbar.Action />
                            <ContentTitle dark={this.state.isDark} title={this.state.searchedTitle} />
                            <Appbar.Action />
                        </Appbar.Header>
                        <Box style={{marginTop: '7px',marginBottom: '7px'}} height={'100%'}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="center" spacing={2} style={{marginBottom: '7px'}}>
                                        {this.state.searchedData.map(r =>
                                            <Grid key={r.id} item>
                                                <Card sx={{ width: 118 }} >
                                                    <Link to={'/info?id='+r.id} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset'}} onClick={this.closeSearched}>
                                                        <CardActionArea>
                                                            <CardMedia
                                                                component="img"
                                                                height="185"
                                                                image={r.image}
                                                                alt={r.name}
                                                            />
                                                            <Divider sx={{height: 1.2}} color={'#00b2ff'}/>
                                                            <Stack style={{paddingRight: '4px',paddingLeft: '4px',paddingBottom: '3px',paddingTop: '1px'}} spacing={0}>
                                                                <Typography style={{textOverflow: "ellipsis",overflow: "hidden",whiteSpace: "nowrap",fontSize: '14px'}}>{r.name}</Typography>
                                                                <Typography style={{textAlign: 'right',fontSize: '14px'}}>{r.status}</Typography>
                                                            </Stack>
                                                        </CardActionArea>
                                                    </Link>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Fab color="primary" variant="extended" aria-label="add" style={{
                            position: "fixed",
                            left: "auto",
                            right: "0",
                            top: "auto",
                            bottom: "0",
                            margin: "16px",
                            justifyContent: "space-between"
                        }} onClick={this.closeSearched}>
                            <ArrowBack style={{color: "white"}} />
                            <Typography style={{fontFamily: "Changa",marginBottom:"3px",color: "white"}}>
                                رجوع
                            </Typography>
                        </Fab>
                    </Dialog>
                    <BottomSheet
                        onRequestClose={() => this.closeSearch()}
                        open={this.state.searchDialog}
                    >
                        <List style={this.state.isDark ? {backgroundColor: "#252e39"} : {backgroundColor: "#ffffff"}}>
                            <ListItem style={{padding:"0px"}}>
                                <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                    <InputLabel style={this.state.isDark ? {color: 'white'} : {color: 'black'}}>كتابة أسم الأنمي</InputLabel>
                                    <OutlinedInput
                                        style={this.state.isDark ? {color: 'white'} : {color: 'black'}}
                                        id="searchItem"
                                        type={'text'}
                                        value={this.state.searchedTitle}
                                        onChange={this.changedSearch}
                                        label="كتابة أسم الأنمي"
                                    />
                                </FormControl>
                            </ListItem>
                            <ListItem style={{padding:"0px"}}>
                                <ListItem onClick={this.goSearch} button style={{margin: "7px", borderRadius: "8px",backgroundColor: "#2196f3", textAlign: "center",placeContent: "center"}}>
                                    <Typography style={{fontFamily: "'Changa'",color: 'white'}}>
                                        بحث
                                    </Typography>
                                </ListItem>
                            </ListItem>
                        </List>
                    </BottomSheet>
                    <Dialog fullScreen PaperProps={{
                        style: {
                            backgroundColor: "#00000000"
                        }
                    }} style={{backgroundColor: "#00000000"}} open={this.state.lottieDialog} onClose={this.closeLottie} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <div style={{backgroundColor: "#00000000",height: '100%'}} id="svgContainer"/>
                    </Dialog>
                    <Dialog fullScreen PaperProps={{style: {backgroundColor: "#00000000"}}} style={{backgroundColor: "#00000000"}} open={this.state.fullscreen} onClose={this.closeFullscreen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                        <Appbar.Header style={this.state.isDark ? {backgroundColor: "#252e39"} : {backgroundColor: "#ffffff"}}>
                            {window.react.state.timer === 0 ? <Appbar.Action icon="arrow-left" onPress={this.closeFullscreen()} /> : <Appbar.Action icon="av-timer" />}
                            <Timers dark={this.state.isDark} title={<Clock/>} />
                            <Appbar.Action />
                        </Appbar.Header>
                        <iframe style={{backgroundColor: "#00000000",height: '100%'}} src={"https://app.sanime.net/ads.html"} sandbox="allow-same-origin allow-scripts allow-popups allow-forms"/>
                    </Dialog>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={this.state.backdrop}>
                        <CircularProgress style={{color: '#00b2ff'}} />
                    </Backdrop>
                    <Link id={'goRandom'} to={this.state.randomAnime} style={{display: 'none'}}></Link>
                </ThemeProvider>
            </BrowserRouter>
        );
    }
}

const ContentTitle = ({ title, subtitle, dark }) => {
    if (dark) {
        return (<Appbar.Content
                title={<Text style={{fontFamily: "Righteous", color: 'white'}}>{title}</Text>}
                subtitle={<Text style={{fontFamily: "Changa", color: 'white'}}>{subtitle}</Text>}
                style={{alignItems: 'center'}}
            />);
    } else {
        return (<Appbar.Content
                title={<Text style={{fontFamily: "Righteous", color: 'black'}}>{title}</Text>}
                subtitle={<Text style={{fontFamily: "Changa", color: 'black'}}>{subtitle}</Text>}
                style={{alignItems: 'center'}}
            />
        );
    }
};

const Timers = ({ title, subtitle, dark }) => {
    if (dark) {
        return (<Appbar.Content
            title={<Text style={{fontFamily: "Righteous", color: 'white'}}>{title}</Text>}
            subtitle={<Text style={{fontFamily: "Changa", color: 'white'}}>{subtitle}</Text>}
            style={{alignItems: 'left'}}
        />);
    } else {
        return (<Appbar.Content
                title={<Text style={{fontFamily: "Righteous", color: 'black'}}>{title}</Text>}
                subtitle={<Text style={{fontFamily: "Changa", color: 'black'}}>{subtitle}</Text>}
                style={{alignItems: 'left'}}
            />
        );
    }
};

const NewItem = () => {
    return (<Card style={{paddingRight: "7px", paddingLeft: "7px", backgroundColor: "#2196f3"}}>
        <Typography style={{color: "white"}}>
            جديد
        </Typography>
    </Card>)
}

class Clock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: 6
        };
        TIMERS = 6;
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        if (TIMERS !== 0) {
            this.setState({
                date: TIMERS - 1
            });
            TIMERS = TIMERS - 1;
        }
    }

    render() {
        return (
            <span>اغلاق خلال {this.state.date}</span>
        );
    }
}
export default withTheme(App);
