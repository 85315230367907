import React, {Component} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {CenterLoading, decodeTool} from "../App";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {NewReleasesSharp} from "@mui/icons-material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import * as Icons from "@mui/icons-material";
import {List, Pagination} from "@mui/material";
import {Appbar} from "react-native-paper";
import IconButton from "@mui/material/IconButton";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import {Text} from "react-native-web";

export default class Genre extends Component {

    componentDidMount() {
        document.getElementById("actionBar").style.display = "block";
        window.react.setState({
            homeTitle: "التصنيفات"
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            error: false,
            isGenre: true,
            newGenreTitle: "",
            newGenreListPage: 1,
            newGenreList: [],
            genreList: [{"data":"genre0","name":"أكشن"},{"data":"genre1","name":"مغامرات"},{"data":"genre2","name":"رسوم متحركة"},{"data":"genre3","name":"سيارات"},{"data":"genre4","name":"كوميدي"},{"data":"genre5","name":"عته"},{"data":"genre6","name":"شياطين"},{"data":"genre7","name":"دراما"},{"data":"genre8","name":"أتشي"},{"data":"genre9","name":"عائلة"},{"data":"genre10","name":"خيال"},{"data":"genre11","name":"لعبة"},{"data":"genre12","name":"حريم"},{"data":"genre13","name":"تاريخي"},{"data":"genre14","name":"رعب"},{"data":"genre15","name":"فتيات"},{"data":"genre16","name":"اطفال"},{"data":"genre17","name":"سحر"},{"data":"genre18","name":"فنون قتال"},{"data":"genre19","name":"آلي"},{"data":"genre20","name":"عسكري"},{"data":"genre21","name":"موسيقى"},{"data":"genre22","name":"الغموض"},{"data":"genre23","name":"باروديا"},{"data":"genre24","name":"شرطة"},{"data":"genre25","name":"نفسي"},{"data":"genre26","name":"رومانسية"},{"data":"genre27","name":"الساموراي"},{"data":"genre28","name":"مدرسي"},{"data":"genre29","name":"الخيال العلمي"},{"data":"genre30","name":"روايات الخيال العلمي"},{"data":"genre31","name":"أولاد"},{"data":"genre32","name":"شوجو"},{"data":"genre33","name":"شوجو آي"},{"data":"genre34","name":"شونين"},{"data":"genre35","name":"شونين آي"},{"data":"genre36","name":"شريحة من الحياة"},{"data":"genre37","name":"الفضاء"},{"data":"genre38","name":"الرياضة"},{"data":"genre39","name":"قوة خارقة"},{"data":"genre40","name":"خارق"},{"data":"genre41","name":"الاثارة"},{"data":"genre42","name":"مأساة"},{"data":"genre43","name":"مصاص دماء"},{"data":"genre44","name":"يوي"},{"data":"genre45","name":"يوري"}],
        };
    }
    animePageChanged = (event, value) => {
        this.setState({newGenreListPage: value});
    }
    checkTheme = () => {
        if (localStorage.getItem("darkMode") == null) {
            return true;
        } else {
            if (localStorage.getItem("darkMode").includes("true")) {
                return true;
            } else {
                return false;
            }
        }
    }
    openGenreList = async (item) => {
        this.setState({
            isGenre: false,
            loading: true
        });

        const isLoggedIn = localStorage.getItem("logged") !== null;
        let url = "";
        var visit = 0;
        visit = await window.react.getVisitCountSync();

        if (isLoggedIn) {
            const data = JSON.parse(localStorage.getItem("loggedData"));
            url = `https://app.sanime.net/function/h10.php?page=genre&email=${data.email}` + `&id=` + item.data+"&visit="+visit;
        } else {
            url = "https://app.sanime.net/function/h10.php?page=genre&id="+item.data+"&visit="+visit;
        }

        fetch(url).then((value) => {
            value.text().then((value) => {
                document.getElementById("actionBar").style.display = "none";
                const list = JSON.parse(value);
                this.setState({
                    newGenre: true,
                    newGenreTitle: item.name,
                    loading: false,
                    newGenreList: list.array_chunk(30),
                    newGenreListPage: 1
                });
            });
        }).catch((error) => {
            this.setState({
                isGenre: true,
                loading: true
            });
        });
    }
    close = () => {
        document.getElementById("actionBar").style.display = "block";
        this.setState({
            isGenre: true,
            loading: true
        });
        window.react.setState({
            homeTitle: "التصنيفات"
        });
    }
    render() {
        if (this.state.isGenre) {
            return <Box style={{marginTop: '7px'}} height={'100%'}>
                {
                    this.state.genreList.map((item) => (
                        <ListItem style={{paddingTop: "2px",paddingBottom: "2px",paddingLeft: "4px",paddingRight: "4px"}} key={item.data}>
                            <Button style={{width:"100%",placeContent: "center"}} onClick={this.openGenreList.bind(this,item)}>
                                {item.name}
                            </Button>
                        </ListItem>
                    ))
                }
            </Box>;
        } else {
            if (this.state.loading) {
                return <CenterLoading/>
            } else {
                if (this.state.newGenreList.length === 0) {
                    return <Box height={'100%'}>
                        <Appbar.Header style={this.checkTheme() ? {backgroundColor: "#252e39"} : {backgroundColor: "#ffffff"}}>
                            <IconButton color="primary" aria-label="menu" onClick={this.close}>
                                <Icons.ArrowBack />
                            </IconButton>
                            <ContentTitle title={this.state.newGenreTitle}  dark={this.checkTheme()} />
                        </Appbar.Header>
                            <Box style={{marginBottom: '7px'}} height={'100%'}>
                                <List>
                                    <ListItem style={{padding:"0px", placeContent: "center"}}>
                                        <Typography style={{fontFamily: "'Changa'"}}>
                                            لاتوجد أنميات في هذا التصنيف
                                        </Typography>
                                    </ListItem>
                                </List>
                        </Box>
                    </Box>;
                } else {
                    return <Box height={'100%'}>
                        <Appbar.Header style={this.checkTheme() ? {backgroundColor: "#252e39"} : {backgroundColor: "#ffffff"}}>
                            <IconButton color="primary" aria-label="menu" onClick={this.close}>
                                <Icons.ArrowBack />
                            </IconButton>
                            <ContentTitle title={this.state.newGenreTitle}  dark={this.checkTheme()} />
                        </Appbar.Header>
                        <Box style={{marginTop: '7px'}}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="center" spacing={2}>
                                        {this.state.newGenreList[this.state.newGenreListPage - 1].map(r =>
                                            <Grid key={r.id} item>
                                                <Card sx={{ width: 100 }} >
                                                    <Link to={'/info?id='+r.id} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset'}}>
                                                        <CardActionArea>
                                                            <CardMedia
                                                                component="img"
                                                                height="185"
                                                                image={r.image}
                                                                alt={r.name}
                                                            />
                                                            <Divider sx={{height: 1.2}} color={'#00b2ff'}/>
                                                            <Stack style={{paddingRight: '4px',paddingLeft: '4px',paddingBottom: '3px',paddingTop: '1px'}} spacing={0}>
                                                                <Typography style={{textOverflow: "ellipsis",overflow: "hidden",whiteSpace: "nowrap",fontSize: '14px'}}>{r.name}</Typography>
                                                                <Typography style={{textAlign: 'right',fontSize: '14px'}}>{r.status}</Typography>
                                                            </Stack>
                                                        </CardActionArea>
                                                    </Link>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Pagination style={{margin: '4px'}} siblingCount={0} size="large" color={"primary"} page={this.state.newGenreListPage} onChange={this.animePageChanged} count={this.state.newGenreList.length} variant="outlined" shape="rounded" />
                        </Box>
                    </Box>;
                }
            }
        }
    }
}


const ContentTitle = ({ title, dark }) => {
    if (dark) {
        return (<Appbar.Content
            title={<Text style={{fontFamily: "Righteous", color: 'white'}}>{title}</Text>}
            style={{alignItems: 'left'}}
        />);
    } else {
        return (<Appbar.Content
                title={<Text style={{fontFamily: "Righteous", color: 'black'}}>{title}</Text>}
                style={{alignItems: 'left'}}
            />
        );
    }
};