import React, {Component} from "react";
import Box from "@mui/material/Box";
import {CenterLoading, decodeTool} from "../App";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {CardContent, List, ListItem, ListItemButton, ListItemText, Pagination} from "@mui/material";
import {BottomSheet} from "../assets/bottom_sheet_mui/src/components/BottomSheet";
import * as PropTypes from "prop-types";
import * as Icons from '@mui/icons-material';
import ListItemIcon from "@mui/material/ListItemIcon";
import Container from "@mui/material/Container";
import {NewReleasesSharp} from "@mui/icons-material";
import Button from "@mui/material/Button";


ListItemIcon.propTypes = {children: PropTypes.node};
export default class Patreon extends Component {

    componentDidMount() {
        document.getElementById("actionBar").style.display = "block";
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            page: 1,
            data: [],
            error: false,
            menuFav: false,
            menuID: '',
            menuWatchData: {},
        };
        window.react.setState({
            homeTitle: "دعم التطبيق"
        });
    }

    checkTheme = () => {
        if (localStorage.getItem("darkMode") == null) {
            return true;
        } else {
            if (localStorage.getItem("darkMode").includes("true")) {
                return true;
            } else {
                return false;
            }
        }
    }
    restartPage = () => {
        this.setState({
            loading: true,
            error: false
        });
        setTimeout(()=> {
            this.prepare();
        },500);
    }

    render() {
        return <Box>
            <Container style={{placeContent: 'center',textAlign: 'center',marginTop: '20px'}}>
                <Card>
                    <NewReleasesSharp style={{margin: "10px"}}/>
                    <CardContent style={this.checkTheme() ? {color: '#ffffff'} : {color: '#000000'}}>
                        مرحبا أخي الكريم شكرا لدعمك التطبيق
                    </CardContent>
                    <CardContent style={this.checkTheme() ? {color: '#ffffff'} : {color: '#000000'}}>
                        يمكنك دعم التطبيق في موقع باتريون عن طريق هذا الرابط
                    </CardContent>
                    <CardContent style={{color: "#2196F3", fontFamily: "sans-serif", fontWeight: "bold"}}>
                        https://patreon.com/sanimeapp
                    </CardContent>
                    <CardContent style={this.checkTheme() ? {color: '#ffffff'} : {color: '#000000'}}>
                        انسخ الرابط وقم بفتحة في المتصفح
                    </CardContent>
                </Card>
            </Container>
        </Box>
    }
}