import React, {Component} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {CenterLoading, decodeTool} from "../App";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {NewReleasesSharp} from "@mui/icons-material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {Appbar} from "react-native-paper";
import IconButton from "@mui/material/IconButton";
import * as Icons from "@mui/icons-material";

export default class Help extends Component {

    componentDidMount() {
        document.getElementById("actionBar").style.display = "block";
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            error: false,
        };
        setTimeout(() => {
            window.react.setState({
                homeTitle: "قسم المساعدة"
            });
        },100);
    }

    checkTheme = () => {
        if (localStorage.getItem("darkMode") == null) {
            return true;
        } else {
            if (localStorage.getItem("darkMode").includes("true")) {
                return true;
            } else {
                return false;
            }
        }
    }

    render() {
       return <Box height={'100%'}>
           <div className={'scroll-wrapper'} style={{height: '100%'}}>
               <iframe className={'scroll-wrapper'} src={'https://tawk.to/chat/60b20cd76699c7280da9914f/1f6ro4b6k'} frameBorder="0" width={'100%'} height={'99%'}/>
           </div>
       </Box>;
    }
}
