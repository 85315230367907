import React, {Component} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {CenterLoading, decodeTool} from "../App";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {NewReleasesSharp} from "@mui/icons-material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import * as Icons from "@mui/icons-material";
import {Pagination} from "@mui/material";
import {Appbar} from "react-native-paper";
import IconButton from "@mui/material/IconButton";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import {Text} from "react-native-web";

export default class News extends Component {

    componentDidMount() {
        document.getElementById("actionBar").style.display = "block";
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            error: false,
            isOpen: true,
            newsTitle: "",
            url: ""
        };
        this.prepare();
    }
    animePageChanged = (event, value) => {
        this.setState({newGenreListPage: value});
    }
    checkTheme = () => {
        if (localStorage.getItem("darkMode") == null) {
            return true;
        } else {
            if (localStorage.getItem("darkMode").includes("true")) {
                return true;
            } else {
                return false;
            }
        }
    }

    prepare = () => {
            fetch("https://app.sanime.net/function/h10.php?page=news").then((value) => {
                value.text().then((value) => {
                    const list = JSON.parse(value);
                    this.setState({
                        data: list,
                        isOpen: false,
                        loading: false
                    });
                });
            }).catch((error) => {
                this.setState({
                    loading: true,
                    error: true
                });
            });
        window.react.setState({
            homeTitle: "الأخبار"
        });
    }
    restartPage = () => {
        this.setState({
            error: false,
            loading: true
        });
        this.prepare();
    }
    close = () => {
        document.getElementById("actionBar").style.display = "block";
        this.setState({
            isOpen: false,
        });
    }
    open = (url) => {
        this.setState({
            url: "about:blank",
        });
        document.getElementById("actionBar").style.display = "none";
        if (this.checkTheme()) {
            this.setState({
                url: url.id + '&style=dark',
                newsTitle: url.title,
                isOpen: true,
            });
        } else {
            this.setState({
                url: url.id + '&style=light',
                newsTitle: url.title,
                isOpen: true,
            });
        }
    }
    render() {
        if (this.state.error) {
            return <Box>
                <Container style={{placeContent: 'center',textAlign: 'center',marginTop: '20px'}}>
                    <Card>
                        <NewReleasesSharp style={{margin: "10px"}}/>
                        <CardContent style={this.checkTheme() ? {color: '#ffffff'} : {color: '#000000'}}>
                            توجد مشكلة في الشبكة حاول مجددا
                        </CardContent>
                        <Button onClick={this.restartPage} style={{margin: "8px", backgroundColor: '#03a9f4',color: 'white'}}>
                            أعادة المحاولة
                        </Button>
                    </Card>
                </Container>
            </Box>
        } else {
            if (this.state.loading) {
                return <CenterLoading/>
            } else {
                if (this.state.isOpen) {
                    return <Box height={'100%'}>
                        <Appbar.Header style={this.checkTheme() ? {backgroundColor: "#252e39"} : {backgroundColor: "#ffffff"}}>
                            <IconButton color="primary" aria-label="menu" onClick={this.close}>
                                <Icons.ArrowBack />
                            </IconButton>
                            <ContentTitle title={this.state.newsTitle}  dark={this.checkTheme()} />
                        </Appbar.Header>
                        <div className={'scroll-wrapper'} style={{height: '100%'}}>
                            <iframe className={'scroll-wrapper'} src={this.state.url} frameBorder="0" width={'100%'} height={'99%'}/>
                        </div>
                    </Box>;
                } else {
                    return <Box height={'100%'}>
                        <Box style={{marginTop: '7px'}}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="center" spacing={2}>
                                        {this.state.data.map(r =>
                                            <Grid key={r.id} item>
                                                <Card sx={{ width: 110 }} onClick={this.open.bind(this,r)}>
                                                    <CardActionArea>
                                                        <CardMedia
                                                            component="img"
                                                            height="185"
                                                            image={r.image}
                                                            alt={r.title}
                                                        />
                                                        <Divider sx={{height: 1.2}} color={'#00b2ff'}/>
                                                        <Stack style={{paddingRight: '4px',paddingLeft: '4px',paddingBottom: '3px',paddingTop: '1px'}} spacing={0}>
                                                            <Typography style={{fontSize: '14px'}}>{r.title}</Typography>
                                                            <Typography style={{textAlign: 'right',fontSize: '14px'}}>{r.time}</Typography>
                                                        </Stack>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>;
                }
            }
        }
    }
}


const ContentTitle = ({ title, dark }) => {
    if (dark) {
        return (<Appbar.Content
            title={<Text style={{fontFamily: "Righteous", color: 'white',textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: '100%',}}>{title}</Text>}
            style={{alignItems: 'left'}}
        />);
    } else {
        return (<Appbar.Content
                title={<Text style={{fontFamily: "Righteous", color: 'black',textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: '100%',}}>{title}</Text>}
                style={{alignItems: 'left'}}
            />
        );
    }
};