import React, {Component} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {CenterLoading, decodeTool} from "../App";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {
    CircularProgress, Dialog,
    FormControl,
    IconButton, ImageList, ImageListItem,
    InputAdornment,
    InputLabel,
    ListItem,
    OutlinedInput
} from "@mui/material";
import {Appbar} from "react-native-paper";
import * as Icons from "@mui/icons-material";
import {Text} from "react-native-web";

export default class Update extends Component {

    componentDidMount() {
        document.getElementById("actionBar").style.display = "block";
    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        setTimeout(() => {
            if (localStorage.getItem("logged") !== null) {} else {
                window.react.checkAccount();
                window.react.setState({
                    randomAnime: "/account"
                });
                setTimeout(() => {
                    document.getElementById('goRandom').click();
                },100);
            }
        },100);
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            error: false,
            loginPage: false,
            loginData: {
                email: "",
                password: "",
                username: "",
                bio: "",
                image: ""
            },
            loginTitle: 'تعديل الحساب',
            loginIsRegister: false,
            showPassword: false,
            loginLoading: false,
            imageListOpen: false,
            imageList: []
        };
        this.prepare();
    }

    closeImageList = () => {
        this.setState({
            imageListOpen: false
        })
    }
    openImageList = () => {
        window.react.setState({
            backdrop: true
        });
        fetch('https://app.sanime.net/function/h10.php?page=images').then((value) => {
            value.text().then((value) => {
                const json = JSON.parse(value);
                this.setState({
                    imageListOpen: true,
                    imageList: json
                });
            }).catch((err) => {
                console.log(err);
                this.setState({
                    loading: false,
                });
            });
            window.react.setState({
                backdrop: false
            });
        }).catch((err) => {
            window.react.setState({
                backdrop: false
            });
            Swal.fire("توجد مشكلة في الشبكة");
        });
    }

    checkTheme = () => {
        if (localStorage.getItem("darkMode") == null) {
            return true;
        } else {
            if (localStorage.getItem("darkMode").includes("true")) {
                return true;
            } else {
                return false;
            }
        }
    }
    prepare = () => {
        setTimeout(() => {
            if (localStorage.getItem("logged") !== null) {
                window.react.setState({
                    homeTitle: 'تعديل الحساب'
                });
                if (localStorage.getItem("logged") !== null) {
                    let data = JSON.parse(localStorage.getItem("loggedData"));
                    this.setState({
                        loginData: data,
                    })
                }
            } else {
                window.react.checkAccount();
                window.react.setState({
                    randomAnime: "/account"
                });
                setTimeout(() => {
                    document.getElementById('goRandom').click();
                },100);
            }
        },100);
    }

    restartPage = () => {
        this.setState({
            loading: true,
            error: false
        });
        setTimeout(()=> {
            this.prepare();
        },500);
    }

    setImage = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: value
            },
        });
        this.closeImageList();
        Swal.fire("تم أختيار الصورة");
    };

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword})
    };
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    onPassword = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: value.target.value,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: this.state.loginData.image
            },
        });
    };
    onBio = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: value.target.value,
                image: this.state.loginData.image
            },
        });
    };
    onImage = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: value.target.value
            },
        });
    };
    onUsername = (value) => {
        if (value.target.value.length < 20) {
            this.setState({
                loginData: {
                    email: this.state.loginData.email,
                    password: this.state.loginData.password,
                    username: value.target.value,
                    bio: this.state.loginData.bio,
                    image: this.state.loginData.image
                },
            });
        } else {
            Swal.fire("يجب أن يكون أسم المستخدم أقل من 20 حرف");
        }
    };
    onEmail = (value) => {
        this.setState({
            loginData: {
                email: value.target.value,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: this.state.loginData.image
            },
        });
    };

    goLogin = () => {
        window.react.setState({
            backdrop:true
        });
        if (localStorage.getItem("logged") !== null) {
            let data = JSON.parse(localStorage.getItem("loggedData"));
            fetch('https://app.sanime.net/function/h10.php?page=update', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: 'e=' + data.email + '&p=' + data.password + '&base=' + btoa(unescape(encodeURIComponent(JSON.stringify(this.state.loginData))))
            })
            .then(response => response.json())
            .then(json => {
                if (json.code === "1") {
                    window.react.setState({
                        backdrop:false
                    });
                    localStorage.setItem("logged",true);
                    localStorage.setItem("loggedData",JSON.stringify(json.message));
                    window.react.checkAccount();
                    history.go(-1);
                } else {
                    Swal.fire(json.message);
                    window.react.setState({
                        backdrop:false
                    });
                }
            })
            .catch(error => {
                Swal.fire("توجد مشكلة في الشبكة");
                window.react.setState({
                    backdrop:false
                });
            });
        }
    }
    openReg = () => {
        if (this.state.loginIsRegister) {
            this.setState({
                loginIsRegister: false
            });
            window.react.setState({
                homeTitle: "تسجيل الدخول"
            });
        } else {
            this.setState({
                loginIsRegister: true
            });
            window.react.setState({
                homeTitle: "أنشاء حساب"
            });
        }
    }
    render() {
        if (this.state.loading) {
            return <CenterLoading/>
        } else {
            return <Box style={{marginTop: '0px',marginBottom: '7px'}} height={'100%'}>
                <Card style={{margin: "13px"}}>
                    <List>
                        <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">أسم المستخدم</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="uername"
                                    type={'text'}
                                    value={this.state.loginData.username}
                                    onChange={this.onUsername}
                                    label="أسم المستخدم"
                                />
                            </FormControl>
                        </ListItem>
                        <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">كلمة المرور</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="passwords"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.loginData.password}
                                    onChange={this.onPassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="كلمة المرور"
                                />
                            </FormControl>
                        </ListItem>
                        <ListItem style={{padding:"0px", placeContent: "center"}}>
                            <ListItem onClick={this.openImageList} button style={{width: '40%',margin: "7px", borderRadius: "5px",backgroundColor: "#2196f3", textAlign: "center",placeContent: "center"}}>
                                <Typography style={{fontFamily: "'Changa'",color: 'white'}}>
                                    {'أختيار صورة'}
                                </Typography>
                            </ListItem>
                        </ListItem>
                        <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">رابط الصورة الشخصية</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="uername"
                                    type={'text'}
                                    value={this.state.loginData.image}
                                    onChange={this.onImage}
                                    label="رابط الصورة الشخصية"
                                />
                            </FormControl>
                        </ListItem>
                        {this.state.loginLoading ? <Container style={{margin: '5px',placeContent: 'center',textAlign: 'center'}}>
                            <CircularProgress style={{color: '#00b2ff'}}/>
                        </Container> : <ListItem style={{padding:"0px", placeContent: "center"}}>
                            <ListItem onClick={this.goLogin} button style={{width: '40%',margin: "7px", borderRadius: "5px",backgroundColor: "#2196f3", textAlign: "center",placeContent: "center"}}>
                                <Typography style={{fontFamily: "'Changa'",color: 'white'}}>
                                    {'تحديث المعلومات'}
                                </Typography>
                            </ListItem>
                        </ListItem>}
                    </List>
                </Card>
                <Dialog fullScreen onClose={this.closeImageList} open={this.state.imageListOpen}>
                    <Appbar.Header style={this.checkTheme() ? {backgroundColor: "#252e39"} : {backgroundColor: "#ffffff"}}>
                        <IconButton color="primary" aria-label="menu" onClick={this.closeImageList}>
                            <Icons.ArrowBack />
                        </IconButton>
                        <ContentTitle title="أختيار صورة" subtitle={''} dark={this.checkTheme()} />
                    </Appbar.Header>
                    <ImageList sx={{margin: "0px",marginTop: "4px" }} cols={3} rowHeight={164}>
                        {this.state.imageList.map((item) => (
                            <ImageListItem key={item.image} onClick={this.setImage.bind(this,item.image)}>
                                <img
                                    src={`${item.image}`}
                                    alt={item.image}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Dialog>
            </Box>
        }
    }
}

const ContentTitle = ({ title, subtitle, dark }) => {
    if (dark) {
        return (<Appbar.Content
            title={<Text style={{fontFamily: "Righteous", color: 'white'}}>{title}</Text>}
            subtitle={<Text style={{fontFamily: "Changa", color: 'white'}}>{subtitle}</Text>}
            style={{alignItems: 'left'}}
        />);
    } else {
        return (<Appbar.Content
                title={<Text style={{fontFamily: "Righteous", color: 'black'}}>{title}</Text>}
                subtitle={<Text style={{fontFamily: "Changa", color: 'black'}}>{subtitle}</Text>}
                style={{alignItems: 'left'}}
            />
        );
    }
};