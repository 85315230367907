import React, {Component} from "react";
import Box from "@mui/material/Box";
import {CenterLoading, decodeTool} from "../App";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Avatar, CardContent, CardHeader, List, ListItem, ListItemButton, ListItemText, Pagination} from "@mui/material";
import {BottomSheet} from "../assets/bottom_sheet_mui/src/components/BottomSheet";
import * as PropTypes from "prop-types";
import * as Icons from '@mui/icons-material';
import ListItemIcon from "@mui/material/ListItemIcon";
import Container from "@mui/material/Container";


ListItemIcon.propTypes = {children: PropTypes.node};
export default class Leaderboard extends Component {

    componentDidMount() {
        document.getElementById("actionBar").style.display = "block";
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            page: 1,
            data: [],
            error: false,
            menuFav: false,
            menuID: '',
            menuWatchData: {},
        };
        this.prepare();
    }

    checkTheme = () => {
        if (localStorage.getItem("darkMode") == null) {
            return true;
        } else {
            if (localStorage.getItem("darkMode").includes("true")) {
                return true;
            } else {
                return false;
            }
        }
    }

    prepare = () => {
        fetch('https://app.sanime.net/secure/leaderboard.php').then((value) => {
            value.text().then((value) => {
                let JS = JSON.parse(value);
                this.setState({
                    data: JS,
                    loading: false
                });
            });
        }).catch((error) => {
            this.setState({
                loading: false,
                error: true
            });
        });
        window.react.setState({
            homeTitle: "قائمة الأبلودر"
        });
    }

    restartPage = () => {
        this.setState({
            loading: true,
            error: false
        });
        setTimeout(()=> {
            this.prepare();
        },500);
    }

    render() {
        if (this.state.loading) {
            return <CenterLoading/>
        } else {
            return <List>
                {
                    this.state.data.length === 0 ? <List>
                            <ListItem style={{padding:"0px", placeContent: "center", marginTop: "5px"}}>
                                <Card>
                                    <Typography style={{fontFamily: "'Changa'",textAlign: "center", margin: "5px"}}>
                                        قائمة الأبلودر خالية
                                    </Typography>
                                </Card>
                            </ListItem>
                        </List> :
                    this.state.data.map((item, index) => (
                        <ListItem style={{paddingTop: "2px",paddingBottom: "2px",paddingLeft: "4px",paddingRight: "4px"}} key={item.id}>
                            {
                                index === 0 ? <Box style={{width: "100%"}}>
                                    <Card sx={{ width: "100%",borderRadius: "8px",backgroundColor: "gold" }}>
                                        <CardActionArea>
                                            <CardHeader
                                                style={{padding: "4px"}}
                                                avatar={
                                                    <Avatar aria-label="recipe" src={item.image}/>
                                                }
                                                title={<span style={{color: "black"}}>{item.name}</span>}
                                                subheader={<Typography style={{fontSize: "14px",opacity: "0.9",color: "black"}}>عدد الحلقات {item.size}</Typography>}
                                            />
                                        </CardActionArea>
                                    </Card>
                                    <div className="hr">
                                        <Icons.Star/>
                                    </div>
                                </Box> : <Card sx={{ width: "100%",borderRadius: "8px", marginLeft: "5px",marginRight: "5px" }}>
                                    <CardActionArea>
                                        <CardHeader
                                            style={{padding: "4px"}}
                                            avatar={
                                                <Avatar aria-label="recipe" src={item.image}/>
                                            }
                                            title={item.name}
                                            subheader={<Typography style={{fontSize: "14px",opacity: "0.8"}}>عدد الحلقات {item.size}</Typography>}
                                        />
                                    </CardActionArea>
                                </Card>
                            }
                        </ListItem>
                    ))
                }
            </List>
        }
    }
}
