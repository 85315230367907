import React, {Component} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {CenterLoading, decodeTool, hex2bin} from "../App";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import {NewReleasesSharp} from "@mui/icons-material";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {Appbar} from "react-native-paper";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
export default class Index extends Component {

    componentDidMount() {
        document.getElementById("actionBar").style.display = "block";
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: [],
            error: false,
            showPin: false,
            pin: {
                open: false,
                message: "",
                title: "",
                link: "",
            }
        };
        this.prepare();
    }

    checkTheme = () => {
        if (localStorage.getItem("darkMode") == null) {
            return true;
        } else {
            if (localStorage.getItem("darkMode").includes("true")) {
                return true;
            } else {
                return false;
            }
        }
    }
    prepare = async () => {
        const isLoggedIn = localStorage.getItem("logged") !== null;
        let url = "";
        var visit = 0;
        visit = await window.react.getVisitCountSync();
        if (isLoggedIn) {
            const data = JSON.parse(localStorage.getItem("loggedData"));
            url = `https://app.sanime.net/function/h10.php?page=latest&email=${data.email}`+"&visit="+visit;
        } else {
            url = `https://app.sanime.net/function/h10.php?page=latest`+"&visit="+visit;;
        }
        fetch(url).then((value) => {
            value.text().then((value) => {
                localStorage.setItem("latestAnime",value);
                let JS = JSON.parse(value);
                this.setState({
                    data: JS["latest"],
                    pin: JS["pin"],
                    loading: false
                });
                window.react.setState({
                    premium: JS["premium"]
                });
                window.react.createAds();
            });
        }).catch((error) => {
            this.setState({
                loading: false,
                error: true
            });
        });
        window.react.setState({
            homeTitle: "الصفحة الرئيسية"
        });
        setTimeout(()=> {
            if (window.isAndroid) {
                this.setState({
                    showPin: true
                });
            }
        },500);
    }

    restartPage = () => {
        this.setState({
            loading: true,
            error: false
        });
        setTimeout(()=> {
            this.prepare();
        },500);
    }

    getDates = (date) => {
        moments.locale("ar-kw");
        date = moments(Date.parse(date)).fromNow();
        return (<Typography style={{textAlign: 'right',fontSize: '12px'}}>{date}</Typography>)
    }


    closePin = () => {
        this.setState({
            pin: {
                open: false,
                message: this.state.pin.message,
                title: this.state.pin.title,
                link: this.state.pin.link,
            }
        })
    }
    render() {
        if (this.state.loading) {
            return <CenterLoading/>
        } else if (this.state.error) {
            return <Box>
                <Container style={{placeContent: 'center',textAlign: 'center',marginTop: '20px'}}>
                    <Card>
                        <NewReleasesSharp style={{margin: "10px"}}/>
                        <CardContent style={this.checkTheme() ? {color: '#ffffff'} : {color: '#000000'}}>
                            توجد مشكلة في الشبكة حاول مجددا
                        </CardContent>
                        <Button onClick={this.restartPage} style={{margin: "8px", backgroundColor: '#03a9f4',color: 'white'}}>
                            أعادة المحاولة
                        </Button>
                    </Card>
                </Container>
            </Box>
        } else {
            return <Box style={{marginTop: '7px',marginBottom: '7px'}} height={'100%'}>
                {
                    this.state.showPin ? <Card style={{margin: "5px",marginTop:'-2px'}}>
                        <Link to={'/appupdate'} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset'}}>
                            <CardActionArea>
                                <CardContent style={{padding: '10px'}}>
                                    <Typography style={{textAlign:'center'}}>
                                        تنبيه مهم بخصوص التطبيق اضغط للقرائة
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Link>
                    </Card> : <Box/>
                }
                <Dialog
                    open={this.state.pin.open}
                    onClose={this.closePin}
                    aria-labelledby={this.state.pin.title}
                    aria-describedby={this.state.pin.message}
                >
                    <DialogTitle id="alert-dialog-title">
                        {this.state.pin.title}
                    </DialogTitle>
                    <DialogContent>
                        <Typography style={{textAlign: "center"}}>
                            {this.state.pin.message}
                        </Typography>
                    </DialogContent>
                    <DialogContent>
                        <Typography color={"#03a9f4"} style={{textAlign: "center"}}>
                            {this.state.pin.link}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closePin} autoFocus>
                            حسنا
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={1.4}>
                            {this.state.data.map(r =>
                                <Grid key={r.id} item>
                                    <Card sx={{ width: 108 }} >
                                        <Link to={'/info?id='+r.id} style={{color: 'unset',cursor: 'unset', textDecoration: 'unset'}}>
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="185"
                                                    image={r.image}
                                                    alt={r.name}
                                                />
                                                <Divider sx={{height: 1.2}} color={'#00b2ff'}/>
                                                <Stack style={{paddingRight: '4px',paddingLeft: '4px',paddingBottom: '3px',paddingTop: '1px'}} spacing={0}>
                                                    <Typography style={{textOverflow: "ellipsis",overflow: "hidden",whiteSpace: "nowrap",fontSize: '14px'}}>{r.name}</Typography>
                                                    <Typography style={{textAlign: 'right',fontSize: '14px'}}>{r.epName}</Typography>
                                                </Stack>
                                            </CardActionArea>
                                        </Link>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <div id={"bannerAds"}>
                </div>
            </Box>;
        }
    }
}
