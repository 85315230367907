import React, {Component} from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {CenterLoading, decodeTool} from "../App";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {
    CircularProgress,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    ListItem,
    OutlinedInput
} from "@mui/material";

export default class Account extends Component {

    componentDidMount() {
        document.getElementById("actionBar").style.display = "block";
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            error: false,
            loginPage: false,
            loginData: {
                email: "",
                password: "",
                username: "",
                bio: "",
                image: ""
            },
            loginTitle: 'تسجيل دخول',
            loginIsRegister: false,
            showPassword: false,
            loginLoading: false,
        };
        this.prepare();
    }

    checkTheme = () => {
        if (localStorage.getItem("darkMode") == null) {
            return true;
        } else {
            if (localStorage.getItem("darkMode").includes("true")) {
                return true;
            } else {
                return false;
            }
        }
    }
    prepare = () => {
        setTimeout(() => {
            if (localStorage.getItem("logged") !== null) {
                window.react.checkAccount();
                window.react.setState({
                    randomAnime: "/"
                });
                setTimeout(() => {
                    document.getElementById('goRandom').click();
                },100);
            } else {
                window.react.setState({
                    homeTitle: "تسجيل الدخول"
                });
            }
        },100);
    }

    restartPage = () => {
        this.setState({
            loading: true,
            error: false
        });
        setTimeout(()=> {
            this.prepare();
        },500);
    }

    handleClickShowPassword = () => {
        this.setState({showPassword: !this.state.showPassword})
    };
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    resetPassword = () => {
        if (this.state.loginData.email.length > 5) {
            this.setState({
                loading:true
            });
            fetch("https://app.sanime.net/function/h10.php?page=reset&e="+this.state.loginData.email).then((value) => {
                value.text().then((value) => {
                    const json = JSON.parse(value);
                    if (json.code === "1") {
                        this.setState({
                            loading:false
                        });
                        Swal.fire(json.message);
                    } else {
                        Swal.fire(json.message);
                        this.setState({
                            loading:false
                        });
                    }
                }).catch((err) => {
                    this.setState({
                        loading:false
                    });
                });
            }).catch(() => {
                Swal.fire("توجد مشكلة في الشبكة");
            });
        } else {
            Swal.fire("نرجو منك كتابة البريد الآكتروني اولا");
        }
    }

    onPassword = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: value.target.value,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: this.state.loginData.image
            },
        });
    };
    onBio = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: value.target.value,
                image: this.state.loginData.image
            },
        });
    };
    onImage = (value) => {
        this.setState({
            loginData: {
                email: this.state.loginData.email,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: ""
            },
        });
    };
    onUsername = (value) => {
        if (value.target.value.length < 20) {
            this.setState({
                loginData: {
                    email: this.state.loginData.email,
                    password: this.state.loginData.password,
                    username: value.target.value,
                    bio: this.state.loginData.bio,
                    image: this.state.loginData.image
                },
            });
        } else {
            Swal.fire("يجب أن يكون أسم المستخدم أقل من 20 حرف");
        }
    };
    onEmail = (value) => {
        this.setState({
            loginData: {
                email: value.target.value,
                password: this.state.loginData.password,
                username: this.state.loginData.username,
                bio: this.state.loginData.bio,
                image: this.state.loginData.image
            },
        });
    };

    goLogin = () => {
        this.setState({
            loading:true
        });
        if (this.state.loginIsRegister) {
            fetch('https://app.sanime.net/function/h10.php?page=register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: 'data=' + btoa(unescape(encodeURIComponent(JSON.stringify(this.state.loginData))))
            })
            .then(response => response.json())
            .then(json => {
                if (json.code === "1") {
                    this.setState({
                        loginLoading:false,
                        loading:false
                    });
                    Swal.fire(json.message);
                    this.openReg();
                } else {
                    Swal.fire(json.message);
                    this.setState({
                        loginLoading:false,
                        loading:false
                    });
                }
            })
            .catch(error => {
                console.log(err);
                this.setState({
                    loginLoading:false,
                    loading:false
                });
            });
        } else {
            let params = new URLSearchParams();
            params.append('email', this.state.loginData.email);
            params.append('password', this.state.loginData.password);
            fetch('https://app.sanime.net/function/h10.php?page=login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: params
            })
            .then(response => response.json())
            .then(json => {
                if (json.code === "1") {
                    this.setState({
                        loading: false,
                    });
                    localStorage.setItem("logged",true);
                    localStorage.setItem("loggedData",JSON.stringify(json.message));
                    Swal.fire("تم تسجيل الدخول");
                    window.react.checkAccount();
                    history.go(-1);
                } else {
                    Swal.fire(json.message);
                    this.setState({
                        loading: false,
                    });
                }
            })
            .catch(error => {
                console.log(err);
                this.setState({
                    loading: false,
                });
            });
        }
    }
    openReg = () => {
        if (this.state.loginIsRegister) {
            this.setState({
                loginIsRegister: false
            });
            window.react.setState({
                homeTitle: "تسجيل الدخول"
            });
        } else {
            this.setState({
                loginIsRegister: true
            });
            window.react.setState({
                homeTitle: "أنشاء حساب"
            });
        }
    }
    render() {
        if (this.state.loading) {
            return <CenterLoading/>
        } else {
            return <Box style={{marginTop: '0px',marginBottom: '7px'}} height={'100%'}>
                <Card style={{margin: "13px"}}>
                    <List>
                        {this.state.loginIsRegister ?  <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">أسم المستخدم</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="uername"
                                    type={'text'}
                                    value={this.state.loginData.username}
                                    onChange={this.onUsername}
                                    label="أسم المستخدم"
                                />
                            </FormControl>
                        </ListItem> : <Box/>}
                        {this.state.loginIsRegister ?  <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">الوصف</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="bios"
                                    type={'text'}
                                    value={this.state.loginData.bio}
                                    onChange={this.onBio}
                                    label="الوصف"
                                />
                            </FormControl>
                        </ListItem> : <Box/>}
                        <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">البريد الألكتروني</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="emails"
                                    type={'email'}
                                    value={this.state.loginData.email}
                                    onChange={this.onEmail}
                                    label="البريد الألكتروني"
                                />
                            </FormControl>
                        </ListItem>
                        <ListItem style={{padding:"0px"}}>
                            <FormControl fullWidth focused color={"primary"} style={{margin: "5px"}} variant="outlined">
                                <InputLabel style={this.checkTheme() ? {color: 'white'} : {color: 'black'}} htmlFor="outlined-adornment-password">كلمة المرور</InputLabel>
                                <OutlinedInput
                                    style={this.checkTheme() ? {color: 'white'} : {color: 'black'}}
                                    id="passwords"
                                    type={this.state.showPassword ? 'text' : 'password'}
                                    value={this.state.loginData.password}
                                    onChange={this.onPassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={this.handleClickShowPassword}
                                                onMouseDown={this.handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="كلمة المرور"
                                />
                            </FormControl>
                        </ListItem>

                        {this.state.loginLoading ? <Container style={{margin: '5px',placeContent: 'center',textAlign: 'center'}}>
                            <CircularProgress style={{color: '#00b2ff'}}/>
                        </Container> : <ListItem style={{padding:"0px", placeContent: "center"}}>
                            <ListItem onClick={this.goLogin} button style={{width: '40%',margin: "7px", borderRadius: "5px",backgroundColor: "#2196f3", textAlign: "center",placeContent: "center"}}>
                                <Typography style={{fontFamily: "'Changa'",color: 'white'}}>
                                    {this.state.loginIsRegister ? 'أنشاء حساب' : 'تسجيل دخول'}
                                </Typography>
                            </ListItem>
                        </ListItem>}
                        <Divider style={{marginTop: '8px',marginBottom: '4px'}}/>
                        {
                            !this.state.loginIsRegister ? <ListItem style={{padding:"0px", placeContent: "center"}}>
                                <ListItem onClick={this.resetPassword} button style={{margin: "7px", borderRadius: "5px",backgroundColor: "transparent", textAlign: "center",placeContent: "center"}}>
                                    <Typography style={{fontFamily: "'Changa'",color: '#2196f3'}}>
                                        استعادة كلمة المرور
                                    </Typography>
                                </ListItem>
                            </ListItem> : <Box/>
                        }
                        <ListItem style={{padding:"0px", placeContent: "center"}}>
                            <ListItem onClick={this.openReg} button style={{margin: "7px", borderRadius: "5px",backgroundColor: "transparent", textAlign: "center",placeContent: "center"}}>
                                <Typography style={{fontFamily: "'Changa'",color: '#2196f3'}}>
                                    {this.state.loginIsRegister ? 'تسجيل دخول' : 'مستخدم جديد ؟ أنشاء حساب'}
                                </Typography>
                            </ListItem>
                        </ListItem>
                    </List>
                </Card>
            </Box>
        }
    }
}
