import React, {Component} from "react";
import Box from "@mui/material/Box";
import {CenterLoading, decodeTool} from "../App";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {CardContent, List, ListItem, ListItemButton, ListItemText, Pagination} from "@mui/material";
import {BottomSheet} from "../assets/bottom_sheet_mui/src/components/BottomSheet";
import * as PropTypes from "prop-types";
import * as Icons from '@mui/icons-material';
import ListItemIcon from "@mui/material/ListItemIcon";
import Container from "@mui/material/Container";
import {NewReleasesSharp} from "@mui/icons-material";
import Button from "@mui/material/Button";


ListItemIcon.propTypes = {children: PropTypes.node};
export default class AppUpdate extends Component {

    componentDidMount() {
        document.getElementById("actionBar").style.display = "block";
    }

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            page: 1,
            data: [],
            error: false,
            menuFav: false,
            menuID: '',
            menuWatchData: {},
        };
        window.react.setState({
            homeTitle: "تحديث التطبيق"
        });
    }

    checkTheme = () => {
        if (localStorage.getItem("darkMode") == null) {
            return true;
        } else {
            if (localStorage.getItem("darkMode").includes("true")) {
                return true;
            } else {
                return false;
            }
        }
    }
    restartPage = () => {
        this.setState({
            loading: true,
            error: false
        });
        setTimeout(()=> {
            this.prepare();
        },500);
    }

    goOtakuc = () => {
        Android.code("YW5kcm9pZC5jb250ZW50LkludGVudCBhcHAgPSBuZXcgYW5kcm9pZC5jb250ZW50LkludGVudChhbmRyb2lkLmNvbnRlbnQuSW50ZW50LkFDVElPTl9WSUVXKTsKYXBwLnNldERhdGEoYW5kcm9pZC5uZXQuVXJpLnBhcnNlKCJodHRwczovL3NhbmltZS5uZXQvIikpOwphY3Rpdml0eS5zdGFydEFjdGl2aXR5KGFwcCk7");
    }

    goAEP = () => {
        Android.code("YW5kcm9pZC5jb250ZW50LkludGVudCBhcHAgPSBuZXcgYW5kcm9pZC5jb250ZW50LkludGVudChhbmRyb2lkLmNvbnRlbnQuSW50ZW50LkFDVElPTl9WSUVXKTsKYXBwLnNldERhdGEoYW5kcm9pZC5uZXQuVXJpLnBhcnNlKCJodHRwczovL3NhbmltZS5uZXQvQUVQLmFwayIpKTsKYWN0aXZpdHkuc3RhcnRBY3Rpdml0eShhcHApOw==");
    }

    render() {
        return <Box>
            <Container style={{placeContent: 'center',textAlign: 'center',marginTop: '20px',marginBottom: '20px'}}>
                <Card>
                    <NewReleasesSharp style={{margin: "10px"}}/>
                    <CardContent style={this.checkTheme() ? {color: '#ffffff'} : {color: '#000000'}}>
                        ***** تنبيه مهم بخصوص التطبيق *****
                    </CardContent>
                    <CardContent style={this.checkTheme() ? {color: '#ffffff'} : {color: '#000000'}}>
                        تم أيقاف هذا النسخة لذلك نرجو منكم تحديث التطبيق للنسخة الجديدة من الرابط في الأسفل
                    </CardContent>
                    <CardContent onClick={this.goOtakuc} style={{color: "#2196F3", fontFamily: "sans-serif", fontWeight: "bold"}}>
                        https://sanime.net/
                    </CardContent>
                </Card>
            </Container>
        </Box>
    }
}